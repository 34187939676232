<template>

  <div class="page center">
    <div class="container mx-auto payment">
      <div class="payment-page">
        <h1 class="text-center page-title" style="padding-top: 40px;color: hsla(0, 0%, 100%, .8)">Төлбөр төлөх багцаа
          сонгоно уу</h1>
        <div class="grid  grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-4"
             v-if="paymentList.length > 0">

          <div v-for="(item, index) in paymentList" :key="index">
            <router-link
                :to="{
                      path:item.ordr === 1 ? '/' : item.ordr === 3 ? '/payment/card' : '/choose/amount',
                      query: item.ordr === 1 ? null : { title: item.title }
                    }"
                class="payment-card-list"
            >
              <div class="card-section">
                <h2 class="title"><span>{{ item.title }}</span></h2>
                <div class="divider"></div>
                <div class="condition-section">
                  <div class="condition-feature" v-for="(data, index) in item.sub" :key="index">
                    <span style="color: hsla(0, 0%, 100%, .8)">{{ data.title }}</span>
                  </div>
                </div>
                <a
                    :href="item.ordr === 1 ? '/' : item.ordr === 3 ? '/payment/card' : `/choose/amount?title=${item.title}`"
                    class="choose-btn"
                >
                  <span style="color: white">Сонгох</span>
                </a>
              </div>
            </router-link>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<style>

.payment-card-list {
  background: #121212;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 30px 40px;
}

.payment-card-list:hover {
  border: 1px solid #E67E22;
}

.card-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: hsla(0, 0%, 100%, .8);
  display: flex;
  justify-content: center;
  align-items: center;

}

.divider {
  height: 1px;
  width: 100%;
  background-color: black;
  margin: 20px 0 0 0;
}

.condition-section {
  margin: 30px 0 30px 0;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.condition-feature {
  margin-top: 0;
  padding-bottom: 10px !important;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  text-align: center;
}

.choose-btn {
  background: #E67E22;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  transition: all .25s !important;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 180px;
}
</style>
<script>

import {mapGetters} from "vuex";
import api from "@store/_boot/baseApi";

export default {
  name: "index",
  components: {},
  computed: {
    ...mapGetters(['app']),
  },

  data() {
    return {
      paymentList: [],
      isLoadingPayment: false,
    }

  },
  created() {
    this.getPaymentPackage();
  },
  methods: {
    getPaymentPackage() {
      this.isLoadingPayment = true;
      api.get('api/m/payment/package')
          .then((data) => {
            this.paymentList = data.data;
            this.isLoadingPayment = false;
          })
          .catch((error) => {
            this.isLoadingPayment = false;
            console.error("Error fetching data:", error);
          });
    },
  }

}
</script>
